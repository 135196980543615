<template>
  <div v-if="menuOpenValue" class="navigation-more">
    <ul>
      <li @click="menuOpenValue = false">
        <div class="icon">
          <img src="@/assets/images/icons/domov.svg" alt="" />
        </div>
        <router-link to="/">Domov</router-link>
      </li>
      <li @click="menuOpenValue = false">
        <div class="icon">
          <img src="@/assets/images/icons/naucneVidea.svg" alt="" />
        </div>
        <router-link to="/naucne-videa">Náučné videá</router-link>
      </li>
      <li @click="menuOpenValue = false">
        <div class="icon">
          <img src="@/assets/images/icons/ciselneZapisy.svg" alt="" />
        </div>
        <router-link to="/ciselne-zapisy">Číselné zápisy</router-link>
      </li>
      <li @click="menuOpenValue = false">
        <div class="icon">
          <img src="@/assets/images/icons/miestaVyucby.svg" alt="" />
        </div>
        <router-link to="/miesta-vyucby">Miesta výučby</router-link>
      </li>
      <li @click="menuOpenValue = false">
        <div class="icon">
          <img src="@/assets/images/icons/helifest.svg" alt="" />
        </div>
        <router-link to="/helifest">Helifest</router-link>
      </li>
      <li @click="menuOpenValue = false">
        <div class="icon">
          <img src="@/assets/images/icons/merch.svg" alt="" />
        </div>
        <router-link to="/merch">Merch</router-link>
      </li>
      <li @click="menuOpenValue = false">
        <div class="icon">
          <img src="@/assets/images/icons/pomoc.svg" alt="" />
        </div>
        <router-link to="/pomoc">Pomoc</router-link>
      </li>

      <router-link
        v-if="$store.state.user != null"
        @click="menuOpenValue = false"
        to="/ucebna/moje-piesne"
        class="button"
      >
        <img src="@/assets/images/icons/zoznam.svg" alt="" /><router-link
          to="/ucebna/moje-piesne"
          >Zoznam piesni</router-link
        >
      </router-link>
      <router-link
        v-if="$store.state.user != null"
        @click="menuOpenValue = false"
        to="/ucebna/moje-kurzy"
        class="button"
        :class="$store.state.user.isSubscribed ? 'green' : ''"
      >
        <img src="@/assets/images/icons/online.svg" alt="" /><router-link
          to="/ucebna/moje-kurzy"
          >Online výučba</router-link
        >
      </router-link>
    </ul>
  </div>
  <section class="menu mobile">
    <div class="navigation">
      <div class="top-nav">
        <ul>
          <li @click="menuOpenValue = false">
            <router-link to="/kosik">
              <div class="icon">
                <img src="@/assets/images/icons/kosik.svg" alt="" />
              </div>
              Košík</router-link
            >
          </li>
          <li>
            <div @click="menuOpen" v-show="menuOpenValue" class="icon">
              <img src="@/assets/images/icons/menuOpen.svg" alt="" />
            </div>
            <div @click="menuOpen" v-show="!menuOpenValue" class="icon">
              <img src="@/assets/images/icons/menuClosed.svg" alt="" />
            </div>
          </li>
          <li @click="menuOpenValue = false">
            <router-link to="/o-nas">
              <div class="icon">
                <img src="@/assets/images/icons/oNas.svg" alt="" />
              </div>
              O nás</router-link
            >
          </li>
        </ul>
      </div>
      <div
        @click="menuOpenValue = false"
        v-if="$store.state.user == null"
        class="login button"
      >
        <img src="@/assets/images/icons/prihlasenie.svg" alt="" /><router-link
          to="/prihlasenie"
          >Prihlásiť sa</router-link
        >
      </div>
      <router-link
        v-else
        @click="menuOpenValue = false"
        to="/ucebna"
        class="login no-padding button"
      >
        <img src="@/assets/images/icons/prihlasenie.svg" alt="" />
        <p>Môj účet</p>
        <a class="logout">
          <img
            @click="this.odhlasenie()"
            src="@/assets/images/icons/odhlasenie.svg"
            alt="odhlasenie"
          />
        </a>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      menuOpenValue: false,
    };
  },
  methods: {
    menuOpen() {
      if (this.menuOpenValue == false) {
        this.menuOpenValue = true;
      } else {
        this.menuOpenValue = false;
      }
    },
    odhlasenie() {
      this.$store.dispatch("odhlasenie");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/_colors.scss";

.button.login.no-padding {
  padding: 0;
  width: auto;
}

.menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 5em;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.top-nav {
  width: 100%;

  ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
    font-size: 70%;

    li {
      margin: 0.6em auto;
      padding: 0.3em 5%;

      display: flex;
      align-items: flex-end;

      justify-content: center;

      font-size: 1.5625em;
      font-style: normal;

      letter-spacing: 0.03em;
      cursor: pointer;

      width: auto;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 400;
        font-family: Adumu;
        font-size: 0.9em;
        letter-spacing: 0.07em;
      }
      img {
        width: auto;
        height: 1.8em;
      }
    }
  }
}

.bot-nav {
  margin: auto auto 5%;
}

.navigation-more,
.menu {
  z-index: 10;
}

.logo img {
  width: 75%;
  margin: 5% auto auto 12.5%;
}

.icon {
  width: 10%;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 1em;
  }
}

.login {
  font-size: clamp(1em, 4.5vw, 1.5em);
  padding: 0.6em 1em;
  position: fixed;
  bottom: 3.45rem;
  left: 50%;
  transform: translateX(-50%);
  width: 8.5em;

  img {
    width: 1em;
  }
  &:hover {
    transform: translateX(-50%) scale(1.1);
  }
  &:has(a.router-link-active.router-link-exact-active) {
    display: none;
  }
}

.login.no-padding {
  img {
    width: 1em;
    padding: 0.8em 0.3em 0.8em 0.8em;
  }

  p {
    padding: 0.4em 0.4em 0.4em 0;
  }
}

li:has(.router-link-active.router-link-exact-active) {
  background: $lt-gr-clr;

  border-radius: 0.625em;

  img {
    filter: brightness(0);
  }
}

section {
  padding: 0;
}

.top-nav > ul > li:nth-child(2) > div > img {
  height: 1.3em;
  margin-top: auto;
  margin-bottom: 0.4em;
}

// menu dropdown
.navigation-more {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 7em;
  width: 90%;
  height: auto;

  background: #ededed;
  outline: 0.2em solid #fef35a;
  filter: drop-shadow(5px 5px 30px rgba(0, 0, 0, 0.5));
  border-radius: 1em;

  box-sizing: border-box;
  padding: 1.5em;

  li {
    // margin: 0.3em auto;
    padding: 0.3em 0.8em;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: #000;

    font-size: 1.05rem;
    font-style: normal;

    letter-spacing: 0.03em;

    width: auto;
    gap: 5%;
    min-width: 10em;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.3em;
      font-weight: 700;
    }
    img {
      width: auto;
      height: 1em;
    }
  }

  ul .button {
    padding: 0.4em 1.2em;
    margin: 0.7em auto;
    font-size: 100%;
    width: 75%;
    justify-content: center;
    box-shadow: 2.5px 2.5px 7.5px 0px rgba(0, 0, 0, 0.25);
  }
}

.login .logout {
  height: 100%;
  fill: #fef35a;
  filter: drop-shadow(-4px 0px 5px rgba(0, 0, 0, 0.15));
  background: url("@/assets/images/icons/elipsaOdhlasenie.svg");
  padding-right: 0.6em;
  background-position: center;
  background-size: cover;
  margin-bottom: 0 !important;
  transition-duration: 0.2s;
}

.logout {
  display: flex;
  align-items: center;
  height: 2.4em;
  justify-content: center;
}

.login .logout:hover {
  transform: scale(1.1);
}

.login .logout img {
  width: 0.95em;
  margin-right: 0;
}
</style>
