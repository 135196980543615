import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

const app = createApp(App);

// Nastavenie warn handlera na ignorovanie varovaní
app.config.warnHandler = function () {
  // Ignore warnings
};

app.use(store).use(router).mount("#app");
