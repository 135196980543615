<template>
  <TheLoader v-if="!$store.state.isLoaded" />
  <AdminLogin
    v-if="
      $store.state.isLoaded &&
      $store.state.user != null &&
      $store.state.user.isAdmin
    "
    class="admin"
  />
  <div class="container">
    <router-view v-if="$store.state.isLoaded" />
  </div>
  <TheMenu v-if="isLoaded" />
  <TheMenuMobile v-if="isLoaded" />

  <ImageOverlay
    :images="$store.state.ciselnyZapisObrazky"
    :visible="$store.state.overlayVisible"
    @close="
      $store.state.overlayVisible = false;
      $store.state.ciselnyZapisObrazky = [];
    "
  />

  <snack-bar />
</template>

<script>
import TheMenu from "@/components/Menu/TheMenu.vue";
import TheMenuMobile from "@/components/Menu/TheMenuMobile.vue";
import TheLoader from "@/components/Functionality/TheLoader.vue";
import SnackBar from "./components/Functionality/SnackBar.vue";
import AdminLogin from "./components/Functionality/AdminLogin.vue";
import ImageOverlay from "./components/Functionality/FullScreenZapisy.vue";

export default {
  components: {
    TheMenu,
    TheMenuMobile,
    TheLoader,
    SnackBar,
    AdminLogin,
    ImageOverlay,
  },
  mounted() {
    window.scrollTo(0, 0);
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.$store.state.isLoaded = true;
        this.isLoaded = true;
      }
    };
    this.$store.dispatch("overeniePrihlasenia");

    var noLogged = 0;

    //overenie stavu prihlásenia, ak sa niečo nepodarilo znova sa pokúsi prihlásiť
    const intervalId = setInterval(() => {
      if (this.$store.state.prihlasenyStav <= 1) {
        noLogged++;
        console.log("noLogged :>> ", noLogged);
        if (noLogged >= 5) {
          clearInterval(intervalId);
        }
      } else if (this.$store.state.prihlasenyStav < 5) {
        console.log(this.$store.state.prihlasenyStav);
        this.$store.state.prihlasenyStav = 0;
        this.$store.dispatch("overeniePrihlasenia");
      } else {
        // Pokud dosáhnete hodnoty větší než 5, zastavte interval
        clearInterval(intervalId);
      }
    }, 1000);

    window.addEventListener("resize", this.handleResize);
    this.handleResize(); // Zavoláme pri vytvorení komponenty na inicializáciu stavu

    //detekcia apple zariadenia pre font
    // Detekcia Apple zariadenia
    const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);

    // Detekcia operačného systému macOS
    const isMacOS = /Mac OS X/.test(navigator.userAgent);

    // Pridanie triedy koreňovému elementu na základe detekcie
    if (isAppleDevice) {
      document.documentElement.classList.add("apple-device");
    } else if (isMacOS) {
      document.documentElement.classList.add("macos-device");
    } else {
      document.documentElement.classList.add("other-device");
    }

    //detekcia safari

    // Check if the browser is Safari

    var isSafari = window.safari !== undefined;
    if (isSafari) document.documentElement.classList.add("safari");

    //zabráni kliknúť pravím a otvoriť inšpektora cez skratky
    this.blockUserBadActivity();
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      const screenWidth = window.innerWidth;

      // Nastavenie overflow podľa šírky obrazovky
      if (screenWidth > 750) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    blockUserBadActivity() {
      // Zabráni kliknúť pravým tlačidlom a otvoriť inšpektora cez skratky
      document.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });

      document.onkeydown = function (e) {
        if (e.keyCode == 123) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
          return false;
        }
        if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
          return false;
        }
      };

      // Zabráni ťahaniu obrázkov
      document.addEventListener("dragstart", (e) => {
        if (e.target.tagName === "IMG") {
          e.preventDefault();
        }
      });

      // Zabráni výberu a sťahovaniu obrázkov (Edge)
      document.querySelectorAll("img").forEach((img) => {
        img.addEventListener("mousedown", (e) => {
          e.preventDefault();
        });
        img.addEventListener("dragstart", (e) => {
          e.preventDefault();
        });
      });
    },
  },
};
</script>

<style lang="scss">
// IMPORT GOOGLE FONTS

// Hammersmith One
@import url("https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap");

// Adumu
@font-face {
  font-family: Adumu;
  src: url(../src/assets/font/Adumu.ttf);
}

// BAHNSCHRIFT
@font-face {
  font-family: Bahnshrift;
  src: url(../src/assets/font/BAHNSCHRIFT.TTF);
}

//IMPORT CSS

// IMPORT GLOBAL COLORS
@import "@/assets/sass/_colors.scss";
// IMPORT DEFAULT Styles
@import "@/assets/sass/_defaultStyles.scss";
// IMPORT GLOBAL Styles
@import "@/assets/sass/globalStyles.scss";

.container {
  height: 100%;
  gap: 4%;

  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
}

@media only screen and (max-width: 1500px) {
  .container {
    gap: 3%;
  }
}

@media only screen and (max-width: 1300px) {
  .container {
    gap: 2%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

.mobile {
  display: none;
}

.computer {
  display: block;
}

@media screen and (max-width: 750px) {
  .computer {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }

  #app {
    padding: 1vh 0 7em; //1vh 0 10em
    height: 100vh;
    gap: 0;
  }
}

.admin {
  position: absolute;
  top: 2em;
  right: 2em;
}
</style>
